@import "layout";
@import "masking-urls";
html {
    //font-size: 62.5%;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.OT_subscriber {
    display: inline-block;
}

.slide-element {
    width: 119px;
    height: 95%;
}

.full-sized {
    --border-radius: 0px;
    --height: 100%;
    --width: 100%;
}

ion-item-divider {
    --background: var(--omni-item-selected-background-color);
    --color: var(--omni-text-color-secondary);
    --padding-start: 16px !important;
    font-weight: 400;
    font-size: 13px;
    height: 40px;
}

ion-title {
    font-weight: 500;
    padding: 0px !important;
}

ion-buttons {
    ion-button {
        font-weight: 400;
    }
}

ion-content {
    // --padding-bottom: var(--omni-bottom-padding);
}

.thumbnail {
    width: 100px;
    height: 75px;
    border: 1px solid lightgray;
    background-color: white;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}


/*ion-toolbar {
  --min-height: 40px;
}*/

.margin-bottom-above-fab {
  margin-bottom: 60px !important;
}

.toolbar-segment {
    height: 44px;
}

ion-footer ion-toolbar ion-icon[slot="icon-only"] {
    size: 24px;
    font-size: 24px !important;
}

:not(.allow-scroll-bar)::-webkit-scrollbar {
    display: none !important;
    width: 10px !important;
    height: 6px !important;
}

:not(.allow-scroll-bar)::-webkit-scrollbar-thumb {
    background-color: rgba(191, 191, 191, 0.7);
}

:not(.allow-scroll-bar)::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

@media (pointer: fine) {
     :not(.allow-scroll-bar)::-webkit-scrollbar {
        display: block !important;
        width: 10px !important;
        height: 6px !important;
    }
}

@media only screen and (min-width: 1200px) {
     :not(.allow-scroll-bar)::-webkit-scrollbar {
        display: block !important;
        width: 10px !important;
    }
}

ion-list-header {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ion-button {
        text-transform: none;
    }
    ion-button,
    ion-label {
        margin-top: 11px;
        margin-bottom: 11px;
    }
}

.select_country {
    --border-radius: 0;
    .modal-wrapper {
        width: 100vw;
        height: 100vh;
    }
}

ion-grid {
    --ion-grid-padding: 0;
}

ion-col {
    --ion-grid-column-padding: 0;
    mark {
        background-color: #415526;
        padding: 0 2px;
        color: var(--omni-custom-white) !important;
        border-radius: 5px;
    }
    mark.selected {
        background-color: var(--omni-primary-color2);
    }
}

ion-app {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.disable-force-statusbar-padding {
    --ion-safe-area-top: 0 !important;
}

.page-title {
    min-height: var(--omni-title-header-height);
    display: flex;
    ion-title {
        // max-width: 80% !important;
        // margin-left: 10% !important;
    }
}

ion-segment {
    height: var(--omni-section-header-height);
    font-size: var(--omni-tabs-font-size);
    font-weight: var(--omni-font-weight-regular);
    width: 100%;
}

// ion-buttons {
//     margin: 0 15px;
// }
.bar-button-ios {
    padding: 0 0px;
}

.item-ios .item-button {
    font-size: var(--omni-controls-font-size);
}

.divider-text {
    text-transform: none !important;
    color: var(--omni-custom-blue);
    font-size: var(--omni-controls-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.closed-status {
    color: var(--omni-text-color-disabled);
    text-transform: none !important;
    font-size: var(--omni-controls-font-size);
    font-weight: var(--omni-font-weight-regular);
}

ion-item-divider,
ind-section-header {
    position: sticky !important;
    top: 0 !important;
}

.open-status {
    color: var(--omni-custom-blue);
    text-transform: none !important;
    color: var(--omni-custom-blue);
    font-size: var(--omni-controls-font-size);
    font-weight: var(--omni-font-weight-regular);
}

[ion-button][disabled]:hover,
a[disabled]:hover,
button[disabled]:hover {
    color: var(--omni-text-color-disabled);
    opacity: 1;
}

.alert-wrapper {
    .alert-title {
        font-size: var(--omni-primary-text-font-size);
        font-weight: 500;
    }
    .alert-message {
        overflow-y: auto !important;
        color: var(--omni-text-color-secondary) !important;
        font-size: var(--omni-secondary-text-font-size);
    }
    .alert-button {
        font-size: var(--omni-section-header-controls-font-size);
    }
}

.contactModalWrapper {
    .modal-wrapper {
        width: calc(100% - 100px) !important;
        height: calc(100% - 100px) !important;
        top: 50px;
        left: calc(50% - (calc(100% - 100px) / 2));
    }
}

.modal-from-chat,
.inMeetingAllocation,
.inMeetingCases {
    &::part(backdrop) {
        background-color: #000 !important;
        position: absolute;
        --backdrop-opacity: 0.4 !important;
    }
    &::part(content) {
        height: 100% !important;
        right: 0px !important;
        // left: 0% !important;
        // top: 0!important;
        position: absolute;
        border-radius: 0 !important;
        @media only screen and (min-width: 0px) and (max-width: 620px) and (orientation: portrait) {
            --width: 100%;
        }
        @media only screen and (min-width: 621px) {
            --width: 60%;
            left: 40%;
            &.fullStretchView {
                --width: 100% !important;
                left: 0 !important;
            }
        }
    }
}

.fullStretchView {
  &::part(backdrop) {
    background-color: #000 !important;
    position: absolute;
    --backdrop-opacity: 0.4 !important;
  }
  &::part(content) {
    height: 100% !important;
    right: 0px !important;
    position: absolute;
    border-radius: 0 !important;
    --width: 100% !important;
    left: 0 !important;
  }
}

.left-pane,
.contact-left-pane,
.account-left-pane {
  main-card .wrapper-item {
    @supports (padding-left: constant(safe-area-inset-left)) {
      padding-inline-start: constant(safe-area-inset-left);
      padding-inline-end: constant(safe-area-inset-right);
    }
    // iOS 11.2+
    @supports (padding-left: env(safe-area-inset-left)) {
      padding-inline-start: env(safe-area-inset-left);
      padding-inline-end: env(safe-area-inset-right);
    }
  }
}

@media only screen and (min-width: 500px) {
  // Remove forced safe-are-insets being applied on iOS split view
  .modal-from-chat,
  .right-pane,
  .account-right-pane,
  .contact-right-pane,
  .inMeetingAllocation,
  .inMeetingCases {
    ion-header ind-page-title ion-toolbar {
      @supports (padding-left: constant(safe-area-inset-left)) {
        padding-left: 0;
      }
      // iOS 11.2+
      @supports (padding-left: env(safe-area-inset-left)) {
        padding-left: 0;
      }
    }
  }

  .left-pane,
  .account-left-pane,
  .contact-left-pane {
    ion-header ion-toolbar {
      @supports (padding-right: constant(safe-area-inset-right)) {
        padding-right: 0;
      }
      // iOS 11.2+
      @supports (padding-right: env(safe-area-inset-right)) {
        padding-right: 0;
      }
    }

    // Header top toolbar doesn't need safe-area-inset
    ion-header ion-toolbar.left-header-toolbar {
      @supports (padding-left: constant(safe-area-inset-left)) {
        padding-left: 0;
      }
      // iOS 11.2+
      @supports (padding-left: env(safe-area-inset-left)) {
        padding-left: 0;
      }
    }

    activities-pane ion-header ion-toolbar.toolbar-segment ind-tabs {
      @supports (padding-right: constant(safe-area-inset-right)) {
        width: calc(100% - 36px); // 36px is the width (including margin) of notification button beside
      }
      // iOS 11.2+
      @supports (padding-right: env(safe-area-inset-right)) {
        width: calc(100% - 36px); // 36px is the width (including margin) of notification button beside
      }
    }
  }

  // Attempt to differentiate safe-area-inset application
  // in different iOS landscape modes (apply inset only to the dynamic island side)
  .landscape-primary {
    .left-pane,
    .account-left-pane,
    .contact-left-pane {
      ion-header ion-toolbar {
        @supports (padding-left: constant(safe-area-inset-left)) {
          padding-left: 0;
        }
        // iOS 11.2+
        @supports (padding-left: env(safe-area-inset-left)) {
          padding-left: 0;
        }
      }

      main-card .wrapper-item {
        @supports (padding-left: constant(safe-area-inset-left)) {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }
        // iOS 11.2+
        @supports (padding-left: env(safe-area-inset-left)) {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }
      }
    }
  }

  .landscape-secondary {
    .left-pane,
    .account-left-pane,
    .contact-left-pane {
      ion-item-divider {
        @supports (padding-left: constant(safe-area-inset-left)) {
          padding-left: constant(safe-area-inset-left);
        }
        // iOS 11.2+
        @supports (padding-left: env(safe-area-inset-left)) {
          padding-left: env(safe-area-inset-left);
        }
      }
    }

    .right-pane,
    .account-right-pane,
    .contact-right-pane {
      ion-header ion-toolbar {
        @supports (padding-right: constant(safe-area-inset-right)) {
          padding-right: 0;
        }
        // iOS 11.2+
        @supports (padding-right: env(safe-area-inset-right)) {
          padding-right: 0;
        }
      }
    }
  }
}

// Page title's toolbar doesn't need padding for safe-area-inset
ind-page-title ion-toolbar {
  padding: 0;
}

ion-item {
    border-width: 0px;
    &,
    &.item-ios {
        //border-bottom: none !important;
        h3 {
            font-family: var(--omni-font-family-base);
            font-size: var(--omni-font-size-base);
            font-weight: var(--omni-font-weight-regular);
        }
        h4 {
            font-family: var(--omni-font-family-base);
            font-size: var(--omni-sec-text-font-size);
            font-weight: var(--font-weight-regular);
            color: var(--omni-text-color-tetiary);
        }
        [item-end]:not(ion-icon),
        [item-content],
        ion-input {
            font-size: var(--omni-primary-text-font-size);
            font-weight: var(--omni-font-weight-regular);
            &,
            p {
                color: var(--omni-text-color2);
            }
        }
    }
    ion-label {
        font-size: var(--omni-primary-text-font-size) !important;
        font-weight: var(--omni-font-weight-regular) !important;
        color: var(--omni-text-color-primary) !important;
        white-space: nowrap;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
    }
    ion-input {
        --placeholder-opacity: 1 !important;
    }
    &.filter-item span {
        font-size: var(--omni-expand-text-font-size) !important;
        color: var(--omni-text-color-secondary) !important;
    }
}

.segment-md .segment-button {
    font-weight: var(--omni-font-weight-regular);
    opacity: 1 !important;
}

.segment-activated {
    color: var(--omni-custom-blue) !important;
}

a[disabled],
button[disabled],
[ion-button][disabled] {
    color: var(--omni-text-color-disabled);
    opacity: 1;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
    .preview-active {
        events-list,
        orders-list,
        activities-pane,
        account-list,
        trending-customers,
        .left-pane ion-footer,
        .left-pane footer-toolbar {
            ion-searchbar,
            ion-toolbar,
            ion-button,
            .keyControlsArea,
            .analyticsContainer {
                display: none !important;
            }
        }
    }
    field-material-management-page {
      .fabViewContainer ion-col {
        min-width: 75px !important;
      }
      asset-details ion-toolbar.right-header-toolbar .right-header-toolbar-inner-wrapper ion-buttons.end-slot ion-button.has-label {
        min-width: 42px !important;
        max-width: 60px !important;
      }
    }
}

.ogeeneeBtnBadge {
    //background-color: var(--omni-custom-blue) !important;
    background: linear-gradient(120deg, rgba(252, 50, 100, 1) 25%, rgba(101, 87, 165, 1) 50%, rgba(0, 111, 209, 1) 100%);
}

ion-item-divider,
.item-divider-ios {
    background-color: var(--omni-item-selected-background-color);
    &,
    p {
        height: 40px !important;
        font-size: var(--omni-section-header-font-size);
        font-weight: var(--omni-font-weight-regular);
    }
    a,
    button {
        font-size: var(--omni-controls-font-size);
        font-weight: var(--omni-font-weight-regular);
    }
    ion-label,
    span {
        font-size: var(--omni-section-header-font-size);
        font-weight: var(--omni-font-weight-regular);
        color: var(--omni-text-color-secondary);
    }
}

.content-ios-light {
    background-color: var(--omni-bg-color4);
    color: var(--omni-text-color-primary);
}

.datetime-popover::part(content),
.subject-popover::part(content),
.calendar-meeting-popover::part(content) {
  width: 95% !important;
  max-width: 450px !important;
}
.datetime-popover::part(content) {
  position: inherit;
}
.datetime-popover{
  --backdrop-opacity: 0.3;
}

.events-popover::part(content) {
  width: 95% !important;
  max-width: 300px !important;
  --height: 48%;
}

.xpPopover {
  &::part(content) {
    max-width: 450px !important;
  }
  ion-thumbnail {
    width: 20px !important;
    height: 20px !important;
  }
}

.add_to_tag::part(content) {
  left: 22px !important;
}

@media only screen and (max-width: 480px) {
  .thumbs-down-reason-popover::part(content) {
    left: 5% !important;
  }
}

.thumbs-down-reason-popover::part(content) {
  width: 90% !important;
  max-width: 390px !important;
}

.privacy-policy-popover::part(content) {
    width: 98% !important;
    // top:5px!important;
    left: 1% !important;
    overflow: hidden !important;
}

.subject-popover {
    top: 0px !important;
}

.subject-popover::part(content) {
    width: 95% !important;
    max-width: 300px !important;
    --min-height: 0%;
    --max-height: 50%;
}

.emailAddress-popover::part(content) {
    width: fit-content;
    max-width: 100%;
}
.customerAssess-popover::part(content) {
  width: fit-content;
  max-width: 100%;
}

.inbound-meeting-popover::part(content) {
    width: 95% !important;
    max-width: 300px !important;
}

.native-lookup-popover-list-view {
    .popover-arrow {
        display: none !important;
    }
    @media only screen and (max-width: 500px) and (orientation: portrait) {
        &::part(content) {
            top: 20% !important;
            height: 60vh !important;
            width: 275px !important;
        }
    }
    @media only screen and (max-width: 500px) and (orientation: landscape) {
      &::part(content) {
          top: 20% !important;
          height: 275px !important;
          width: 275px !important;
      }
    }
    @media only screen and (min-width: 500px) {
        &::part(content) {
            top: 20% !important;
            height: 500px !important;
            width: 500px !important;
        }
    }
}

.ind-alert-msg {
  --width: 360px !important;
  &::part(content) {
      width: 360px !important;
  }
}

.native-ind-alert-msg {
  .popover-arrow {
      display: none !important;
  }
  @media only screen and (max-width: 500px) and (orientation: portrait) {
      &::part(content) {
          top: 20% !important;
          width: 275px !important;
      }
  }
  @media only screen and (max-width: 500px) and (orientation: landscape) {
    &::part(content) {
        top: 20% !important;
        height: 275px !important;
        width: 275px !important;
    }
  }
  @media only screen and (min-width: 500px) {
    &::part(content) {
          top: 20% !important;
          height: inherit !important;
          width: 500px !important;
      }
  }
}

.native-alert-with-input-list-view {
  .popover-arrow {
    display: none !important;
  }

  &::part(content) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.lookup-popover-list-view {
    --width: 460px !important;
    --height: 500px !important;
    &::part(content) {
        width: 460px !important;
        height: 400px !important;
    }
}

.tooltip-popover {
    width: auto !important;
    ion-label {
        white-space: normal !important;
    }
}

.overflowEllipsis {
    overflow: auto;
    text-overflow: ellipsis;
}

ion-modal.fullscreen::part(content) {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
    --background: transparent;
    --backdrop-opacity: 1;
}

ion-modal.fullscreenOnModal::part(content) {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
    --background: transparent;
    // --backdrop-opacity: 1.0;
}

.modal-fullscreen {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        // background: transparent !important;
        opacity: 0.9 !important;
        background-image: url(../assets/imgs/assistant_background.png);
        background-size: auto;
        background-position: top;
    }
}

.overflowHiddenAndEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

ion-toast.inbound-meeting-toast button {
    background: green;
    padding: 0 10px;
    height: 1.6em;
    margin-right: 10px;
    font-size: 1em;
    font-weight: 500;
    &:hover {
        color: var(--omni-custom-white) !important;
    }
}

@media screen and (max-width: 450px) {
    .datetime-popover::part(content) {
        left: unset !important;
        right: 5% !important;
        width: 90% !important;
    }
    .lookup-popover-list-view {
      --width: 80% !important;
      &::part(content) {
        width: 80% !important;
    }
    }
}

.account-plan-select {
  &::part(content) {
    width: fit-content !important;
    max-width: 300px !important;
  }
    .select-list-popup-list ion-label {
        white-space: normal !important;
    }
}

.itemSelected {
    --ion-background-color: var(--omni-highlighted-section-header-background);
    ion-row {
      background-color: var(--omni-highlighted-section-header-background) !important;
    }
}

.itemUnSelected {
    background-color: var(--omni-bg-color4);
}

.indGrey-color {
    color: var(--omni-text-color-tetiary) !important;
}

.hide {
    display: none;
}

.boldText {
    //font-weight: bold !important;
}

.enabledText {
    color: var(--omni-primary-color1);
}

.disabledText {
    color: var(--omni-text-color4);
    font-size: var(--omni-controls-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.form-field-label-text {
    color: var(--omni-label-color) !important;
    --font-size: var(--omni-form-label-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.form-field-user-input-text {
    color: var(--omni-user-input-color) !important;
    --font-size: var(--omni-form-label-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.form-field-placeholder-text {
    color: var(--omni-placeholder-color) !important;
    --font-size: var(--omni-form-label-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.toast-ios {
    .toast-container {
        border-radius: 0.65rem !important;
    }
    .toast-message {
        font-weight: 400;
    }
    .toast-button {
        font-weight: bold;
    }
}

.contactModel {
    .modal-wrapper {
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
    }
}

.privacy-policy-popover::part(content) {
    width: 98% !important;
    // top:5px!important;
    left: 1% !important;
    overflow: hidden !important;
    height: 80% !important;
}
.privacy-policy-popover .popover-viewport {
  height: 100% !important;
}

.sentiment-popover::part(content) {
    min-width: 50px !important;
    width: 50px !important;
    height: 150px !important;
}

.timelineFilter {
    .popover-md::part(content) {
        top: 0px !important;
    }
}

.toolbar-ios ion-segment-button {
    max-width: 100% !important;
}

:not(.allow-scroll-bar)::-webkit-scrollbar {
    display: none !important;
}

.scroll-content {
    overflow: auto;
    padding-bottom: 0px !important;
}

.offline-indicator-root {
  position: fixed;

  &::before {
    content: "Offline";
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 0;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    width: 100%;
    z-index: 2;
    background-color: black;
  }
}

ion-badge {
    &.scheduler-badge {
        color: #000 !important;
        background-color: #dddddd40 !important;
        font-weight: 500 !important;
        cursor: pointer !important;
    }
}

.custom-scheduler-backdrop {
    .popover-arrow {
        display: none;
    }
    &::part(content) {
        position: fixed;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        width: 95% !important;
        max-width: 400px !important;
        //height: 115px;
    }
}

.custom-scheduler-backdrop+.dx-overlay-wrapper {
    display: none;
}

.case-product-popover {
    ion-list {
        &.list {
            margin: 0px !important;
            ion-item {
                padding-left: 10px;
            }
        }
    }
}

ion-popover {
  &::part(content){
    max-height: 80%;
    --width: 225px !important;
  }
  .item-radio ion-label {
    color: inherit;
  }
}

// Ionic 6 changed default behaviour for ion-select component
// It focuses the first selection list item and indicate the focus with darker background colour
// This is to counter that effect but it's not perfect as hover effect still does not work with
// ion-focused class on it. Might need further investigation.
ion-popover ion-select-popover ion-radio-group {
  ion-item.ion-focused::part(native) {
    --background-focused: white;
  }
}

.alert-with-input-list-view-for-meeting-content-reason {
  .alert-option {
    padding-bottom: unset !important;
  }
  &::part(content) {
    min-width: 350px !important;
  }
  .separationLine {
    display: block !important;
  }
}

.alert-with-input-list-view-for-callplan-adjustment-reason {
  --width: 360px !important;
  &::part(content) {
      width: 360px !important;
      position: inherit;
  }
}

.alert-with-input-list-view-for-opportunity-reason{
  ion-input{
    min-height:unset;
    .input-wrapper{
      display: flex;
    }
    &.input-text{
      padding-left: 4px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.scheduler-city-popover::part(content) {
  width: 95% !important;
  max-width: 300px !important;
}

.popover-ios.xpPopover::part(content) {
    width: 250px !important;
}

.popover-ios.thumbs-down-reason-popover {
    ion-backdrop {
        opacity: 0.5 !important;
    }
    top: 0px !important;
    &::part(content) {
        position: relative;
        max-width: 325px !important;
        width: 325px !important;
        display: flex;
        height: 220px;
        align-items: center;
        overflow: hidden !important;
        border-radius: 13px;
        margin: auto !important;
        top: unset !important;
        left: unset !important;
        ion-label {
            font-size: var(--omni-secondary-text-font-size) !important;
        }
        .scroll-content {
            padding-bottom: 0px !important;
        }
    }
}

.popover-ios.presentation-filter::part(content) {
    width: 250px;
}

.popover-ios.activities-filter::part(content) {
    max-height: 83%;
}

.popover-ios.livetimeURL-popover {
    &::part(content) {
        width: 250px;
    }
    ion-content {
        ion-list {
            margin: 0;
        }
        ion-label {
            white-space: inherit !important;
            div {
                max-height: 250px;
                overflow: scroll;
            }
        }
    }
}

.filterStyleHeading {
    color: var(--omni-custom-blue) !important;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--omni-section-header-controls-font-size);
    font-weight: var(--omni-font-weight-regular);
}

.filterStyleHeading:hover {
    background: none;
}

[disabled] .filterStyleHeading {
    color: var(--omni-text-color-disabled) !important;
}

.filterHeaderTrimmedText {
    max-width: 65% !important;
}

.filterHeaderCount {
    margin-left: 2px !important;
}

.keyControlsArea {
    background-color: var(--omni-bg-color4);
    overflow-x: scroll;
    button:hover {
        opacity: 1 !important;
    }
    ion-button {
        --padding-start: 0 !important;
        --color: var(--omni-section-header-button-color) !important;
        font-size: var(--omni-section-header-controls-font-size) !important;
    }
}

ion-button[disabled] {
    --ion-color-base: var(--omni-text-color-disabled) !important;
    --opacity: 1;
    --color: var(--omni-text-color-disabled) !important;
}

// .headingText {
//     color: rgb(51, 51, 51) !important;
//     justify-content: start;
//     width: 90%;
//     max-width: 90%;
//     //overflow: hidden;
//     .button-inner {
//         justify-content: start;
//     }
// }
.filterIcon {
    font-size: 1.8em !important;
    margin-left: 5px;
}

.backBtn {
    color: var(--omni-custom-dark-grey);
    font-size: 40px !important;
    width: auto !important;
}

.leftScreenHeaderTitle {
    .toolbar-title-ios {
        width: 80%;
        margin: auto;
    }
}

.main-tool-header-title {
    // .toolbar-title-ios {
    font-size: var(--omni-page-title-left-side-font-size);
    font-weight: bold !important;
    width: 80%;
    margin: auto;
    // }
}

.rightScreenHeaderTitle {
    .toolbar-title {
        margin: auto;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.back-btn {
    font-size: 1.5em;
    --color: var(--omni-custom-blue);
    //background-color: $custom-white !important;
}

.back-btn:hover {
    --color: var(--omni-custom-blue) !important;
}

.meeting-activities-nav {
    new-activity,
    email-activity-detail,
    case-management-detail,
    new-sample-activity,
    surgery-order-details,
    procedure-tracker,
    procedure-tracker-details,
    follow-up-action-detail,
    resource-details,
    survey-details {
        background: var(--omni-bg-color1);
        ion-header,
        ind-page-title,
        ion-footer {
            display: none;
        }
        @media screen and (min-width: 641px) {
            width: 60%;
            left: 20% !important;
        }
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        ion-content {
            // height: 100% !important;
            background: #fff;
            .scroll-content {
                overflow-x: hidden;
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
    contact-consent-capture {
        background: var(--omni-bg-color1);
        .mainWrapper {
            @media screen and (min-width: 641px) {
                width: 60% !important;
                left: 20% !important;
                margin: auto;
            }
            overflow-y: scroll;
            height: 100% !important;
            background: #fff;
            border-left: 1px solid lightgray;
            border-right: 1px solid lightgray;
        }
    }
    capture-customer-inquire {
        ion-footer {
            display: none;
        }
        ion-header {
            ion-buttons {
                margin: 0 !important;
            }
            @media screen and (min-width: 641px) {
                width: 60%;
                left: 15% !important;
            }
            .toolbar-ios:last-child .toolbar-background-ios {
                border: none;
            }
            ion-title {
                display: none;
            }
        }
    }
    capture-allocation-order,
    resource-interaction-detail,
    consent-details {
        ion-footer {
            display: none;
        }
        ion-header {
            ion-buttons {
                margin: 0 !important;
            }
            @media screen and (min-width: 641px) {
                width: 60%;
                left: 20% !important;
            }
            .toolbar-ios:last-child .toolbar-background-ios {
                border: none;
            }
            ion-title {
                display: none;
            }
        }
        ion-content {
            @media screen and (min-width: 641px) {
                width: 60%;
                left: 20% !important;
            }
        }
        .scroll-content {
            height: 100% !important;
            @media screen and (min-width: 641px) {
                width: 100%;
            }
            ion-item:first-child {
                margin-top: 1px;
                border-top: 1px solid lightgray;
            }
            background: #fff;
            border-left: 1px solid lightgray;
            border-right: 1px solid lightgray;
            .scroll-content {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.preferred-activity {
    &::part(content) {
        background: transparent !important;
        min-width: 250px !important;
        min-height: 250px !important;
        background: #80b53a !important;
        border-radius: 50% !important;
    }
}

.secondaryTextColorAndfont {
    color: var(--omni-text-color-secondary) !important;
    font-size: var(--omni-secondary-text-font-size) !important;
}

.placeholderTextStyle {
    color: var(--omni-placeholder-color) !important;
}

.no-padding {
    padding: 0;
}

.seventyPercentWidth {
    max-width: 70% !important;
}

.moreOptionsPopover {
    ion-backdrop {
        background: #000;
    }
    ion-toolbar,
    .toolbar-content {
        display: flex !important;
        flex-direction: column-reverse !important;
        align-self: normal;
        ion-buttons {
            display: flex;
            flex-direction: row-reverse;
        }
    }
    &::part(content) {
      //padding-left: 10px;
      margin-right: 10px;
      max-width: max-content !important;
      height: auto !important;
      bottom: calc(constant(safe-area-inset-bottom) + 70px) !important;
      bottom: calc(env(safe-area-inset-bottom) + 70px) !important;
      right: constant(safe-area-inset-right, 0px) !important;
      right: env(safe-area-inset-right, 0px) !important;
      top: auto !important;
      left: auto !important;
    }
    .seventyPercentWidth {
        max-width: 70% !important;
    }

    button {
        margin: 0 !important;
        height: 40px !important;
    }

    .placeholderEmptyDiv {
        .item-inner,
        .input-wrapper {
            border: 0px !important;
        }
    }
    //bottom: 0 !important;
}

.extendedMoreOptionsPopoverMasterLandscape {
  @extend .moreOptionsPopover;

  @media only screen and (orientation: landscape) {
    &::part(content) {
      right: calc(env(safe-area-inset-right, 0px) + 60%) !important;
      right: calc(constant(safe-area-inset-right) + 60%) !important;
    }
  }

  @media only screen and (orientation: portrait) {
    &::part(content) {
      right: calc(env(safe-area-inset-right, 0px) + 60%) !important;
      right: calc(constant(safe-area-inset-right) + 60%) !important;
    }
  }
}

.extendedMoreOptionsPopoverDetailsLandscape {
  @extend .moreOptionsPopover;

  @media only screen and (orientation: landscape) {
    &::part(content) {
      right: calc(env(safe-area-inset-right, 0px)) !important;
      right: calc(constant(safe-area-inset-right)) !important;
    }
  }

  @media only screen and (orientation: portrait) {
    &::part(content) {
      right: calc(env(safe-area-inset-right, 0px)) !important;
      right: calc(constant(safe-area-inset-right)) !important;
    }
  }
}

.selector-item {
    .checkmark-icon {
        background-image: url(../assets/imgs/selectors_checkmark.svg);
    }
    .add-icon {
        background-image: url(../assets/imgs/selectors_add.svg);
    }
    .remove-icon {
        background-image: url(../assets/imgs/selectors_remove.svg);
    }
}

.selector-item {
    .remove-icon,
    .add-icon,
    .checkmark-icon {
        width: var(--omni-selectors-icon-size) !important;
        height: var(--omni-selectors-icon-size) !important;
        min-height: var(--omni-selectors-icon-size) !important;
        background-repeat: no-repeat;
    }
    .item-inner {
        border-bottom: 0.55px solid #c8c7cc !important;
    }
}

.italics {
    font-style: italic;
}

ion-checkbox {
    // margin: 8px 15px 8px 2px;
    --size: 22px;
}

ion-popover {
    .popover-wrapper {
        margin: auto !important;
    }
    ion-label {
        white-space: normal !important;
        width :fit-content !important;
        width :fit-content !important;
    }
}

.presentation-popover {
    ion-label {
        white-space: normal !important;
    }
}

.saveSearchAlert {
    .alert-sub-title {
        color: red;
    }
    .alert-message {
        padding-bottom: 10px;
    }
}

.journey-touchpoint-select {
    &::part(content) {
        bottom: unset !important;
    }
}

.interest-insigts-popover,
.relationship-insigts-popover,
.xpPopover {
    .multi-select-popover {
        ion-list {
            margin-bottom: 0px !important;
        }
    }
}

.contact-timeline-filter {
    ion-list {
        margin: 0px !important;
    }
}

.no-data-message {
    color: #8f8e94;
    // padding-left: 20px;
    // padding-top: 21px;
    font-size: 16px;
    margin: 0px;
    padding: 21px 10px 0px 20px;
}

.vectorimageleft {
    --background: url(../assets/imgs/empty_screen_2os.svg) no-repeat left bottom;
    // background-position:  50px;
    // background-repeat: ;
}

.vectorimageleft-scheduler {
    background-image: url(../assets/imgs/empty_screen_2os.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
}

.vector-image-right {
    background-image: url(../assets/imgs/empty_screen_3os.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 80%;
    --background: initial;
    // background-position: bottom 135px right;
}

.os_background {
    position: fixed;
    bottom: 3rem;
    width: 50%;
    height: auto;
    right: 0px;
    content: url(../assets/imgs/empty_screen_3os.svg);
}

.os_background-list {
    position: fixed;
    bottom: 0px;
    right: 0px;
    content: url(../assets/imgs/empty_screen_3os.svg);
}

.os_background-list-android {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 50%;
    content: url(../assets/imgs/empty_screen_3os.svg);
}

.os_background-list-ios {
    position: fixed;
    bottom: 44px;
    right: 0px;
    content: url(../assets/imgs/empty_screen_3os.svg);
}

.icon-back-home {
    background-image: url(../assets/imgs/back_to_home_btn.svg);
}

@media only screen and (max-width: 500px) {
    .os_background-list {
        bottom: 80px;
        left: 0px;
        content: url(../assets/imgs/empty_screen_2os.svg);
    }
    .os_background-list-ios {
        bottom: 50px;
        left: 0px;
        content: url(../assets/imgs/empty_screen_2os.svg);
    }
    .os_background {
        bottom: 50px;
        left: 0px;
        z-index: -1;
        width: 0%;
        content: url(../assets/imgs/empty_screen_2os.svg);
    }
    .os_background_ios {
        width: 0%;
    }
    .os_background-list-android {
        position: fixed;
        bottom: 0px;
        right: 0px;
        content: url(../assets/imgs/empty_screen_2os.svg);
    }
}

@media only screen and (min-width: 500px) {
    .contactProfileScientificInfoModal {
        width: 60%;
        position: absolute;
        left: 40%;
    }
    .os_background-list-ios {
        width: 50%;
    }
}

.os_background_ios {
    width: 50% !important;
}

.displayPill {
    border-radius: 15px;
    background-color: var(--omni-custom-blue);
    color: var(--omni-custom-white);
    line-height: 20px;
    font-size: 13px;
    padding: 2px 5px 2px 5px;
}

.pluse-icon {
    background-image: url(../assets/imgs/ios_add_3x_blue.svg);
    width: 32px !important;
    height: 32px !important;
    font-size: 1.0em;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.pluse-icon-disabled {
  background-image: url(../assets/imgs/ios_add_3x_grey.svg);
  width: 32px !important;
  height: 32px !important;
  font-size: 1.0em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.refresh-icon {
  background-image: url(../assets/imgs/syncIcon.svg);
  width: 32px !important;
  height: 32px !important;
  font-size: 1.0em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.carto-checkbox{
  background-image: url(../assets/imgs/selectors_checkmark.svg);
  width: 32px !important;
  height: 32px !important;
  font-size: 1.0em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.sentiment-icon {
  background-image: url(../assets/imgs/sentiment-icon.svg);
  width: 32px !important;
  height: 32px !important;
  font-size: 1.0em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.pluse-icon-action-bar {
    background-image: url(../assets/imgs/ios_add_3x_blue.svg);
    width: 16px !important;
    height: 16px !important;
    font-size: 1.0em;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.completed-icon {
    background-image: url(../assets/imgs/contact_conn.png);
    width: 14px !important;
    height: 14px !important;
    font-size: 1.0em;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.clearSuggestions {
    background-image: url(../assets/imgs/ios-close-button.svg);
}

.indegene-email {
    background-image: url(../assets/imgs/email.svg);
}

.indegene-sample_activity {
    background-image: url(../assets/imgs/sample_activity_agendaIcon.svg);
}

.indegene-ios-close-icon {
    background-image: url(../assets/imgs/ios-close-button.svg);
}

.indegene-email-white {
    background-image: url(../assets/imgs/email_white.svg);
}

.indegene-email_blue-white {
    background-image: url(../assets/imgs/message_check_blue_white.svg);
}

.indegene-email_orange-white {
    background-image: url(../assets/imgs/message_check_orange_white.svg);
}

.indegene-email_red-white {
    background-image: url(../assets/imgs/message_check_red_white.svg);
}

.indegene-email_green-white {
    background-image: url(../assets/imgs/email_green_white.svg)
}

.indegene-sample_activity-white {
    background-image: url(../assets/imgs/open_sample_activity_white.svg);
}

.indegene-sample_activity_completed-white {
    background-image: url(../assets/imgs/compl_sample_activity_white.svg);
}

.indegene-case-intake-white {
    background-image: url(../assets/imgs/customer_Inquiry_3x_white.svg);
}

.indegene-case-intake-completed-white {
    background-image: url(../assets/imgs/case-intake-completed_white.svg);
}

.indegene-coaching-icon {
    background-image: url(../assets/imgs/tools_coaching.svg);
}

.add_icon {
    background-image: url(../assets/imgs/ios_add_3x.svg);
}

.arrow-icon {
    color: var(--omni-text-color-arrow);
    padding-left: var(--omni-level-two-padding);
    margin-right: var(--omni-no-margin) !important;
}

ion-modal::part(content) {
    --width: 100%;
    --height: 100%;
    --border-radius: 0px;
}

ion-modal.popup-modal::part(content) {
  --width: 100%;
  --height: auto;
  --min-height: 100px;
  --max-height: 85%;
  --border-radius: 6px;
}
ion-modal.datetime::part(content) {
  width: 320px;
  height: fit-content;
}

.indegene-edit {
    background-image: url(../assets/imgs/edit-icon.svg);
}

.indegene-delete {
    background-image: url(../assets/imgs/delete-icon.svg);
}

.indegene-clone {
  background-image: url(../assets/imgs/clone-icon.svg);
}

.indegene-scrap {
  background-image: url(../assets/imgs/scrap-icon.svg);
}

.indegene-save {
    background-image: url(../assets/imgs/xp_save.svg);
    width: 32px !important;
    height: 32px !important;
}

.indegene-save-active {
    background-image: url(../assets/imgs/xp_save_active.svg);
    width: 32px !important;
    height: 32px !important;
}

.indegene-genee-white {
    background-image: url(../assets/imgs/xp_genee.svg);
    width: 30px !important;
    height: 33px !important;
}

.indegene-thumbs-up {
    background-image: url(../assets/imgs/thumbs_up.png);
}

.indegene-thumbs-down {
    background-image: url(../assets/imgs/thumbs_down.png);
}

.proximity-warn {
    background-image: url(../assets/imgs/warning-outline.svg);
}

.recommended-star {
  background-image: url(../assets/imgs/recommendation-star.svg);
  background-repeat: no-repeat;
}

.list-item-wrapper {
    width: 100% !important;
}

.grid-item-wrapper {
    height: 170px;
    width: 50%;
}

.analyticsWrapper>.swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0 !important;
}

.segment-button-indicator {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
}

.icon-alignment {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 1.2em;
    height: 1.5em;
    vertical-align: middle;
}

.accounts-icon {
    background-image: url(../assets/imgs/tools_accounts.svg);
}

.account-management-icon {
    background-image: url(../assets/imgs/tools_account_management.svg);
}

.ordermgmt-icon {
    background-image: url(../assets/imgs/tools_order_mgmt.svg);
}

.calendar-icon {
    background-image: url(../assets/imgs/tools_calendar.svg);
}

.call-plan-icon {
    background-image: url(../assets/imgs/tools_call_plans.svg);
}

.case-intake-icon {
    background-image: url(../assets/imgs/case-intake.svg);
}

.coaching-icon {
    background-image: url(../assets/imgs/tools_coaching.svg);
}

.contacts-icon {
    background-image: url(../assets/imgs/tools_contacts.svg);
}
.pharmacovigilance-icon {
  background-image: url(../assets/imgs/tools_pharmacovigilance.svg);
}

.presentations-icon {
    background-image: url(../assets/imgs/tools_presentations.svg);
}

.analytics-icon {
    background-image: url(../assets/imgs/tools_analytics.svg);
}

.time-off-icon {
    background-image: url(../assets/imgs/tools_timeoff.svg);
}
.survey-icon {
  background-image: url(../assets/imgs/survey-icon.svg);
}

.resources-icon {
    background-image: url(../assets/imgs/tools_resources.svg);
}

.allocation-icon {
    background-image: url(../assets/imgs/tools_customer_allocations.svg);
}

.consent-icon {
    background-image: url(../assets/imgs/consent-signature.svg);
}

.indegene-eligibility-icon {
  background-image: url(../assets/imgs/eligibility.svg);
}

.scientific-plan-icon {
    background-image: url(../assets/imgs/scientific-plan.svg);
}

.marketing-plan-icon {
  background-image: url(../assets/imgs/marketing-plan.svg);
}

.quote-tool-icon {
  background-image: url(../assets/imgs/quotes_tool.svg);
}

.opportunity_mgmt_tool {
    background-image: url(../assets/imgs/opportunity_mgmt_tool.svg);
}

.market_scan_tool_icon {
    background-image: url(../assets/imgs/market_scan_tool_icon.svg);
}

.events_tool_icon {
    background-image: url(../assets/imgs/events_tool_icon.svg);
}

.tools_link {
    background-image: url(../assets/imgs/tools_link.svg);
}
//TODO: replace icon with field material once received
.field-material-icon {
  background-image: url(../assets/imgs/tools_field_material.svg);
}

.indegene-search-share-default {
    background-image: url(../assets/imgs/share_search_default.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.share-search-sync-pending {
    background-image: url(../assets/imgs/share_search_pending.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.share-search-sync-success {
    background-image: url(../assets/imgs/share_search_success.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.cancel-icon {
    background-image: url(../assets/imgs/ios-close-button.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.description-container-for-genee-box {
    max-width: 100% !important;
    margin: 0 0 0 10px !important;
    color: var(--omni-text-color-secondary);
    white-space: pre-wrap;
    border-radius: 0px 20px 20px 20px;
    background-color: var(--omni-bg-color4);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    //border: 0.5px solid var(--omni-bg-color1) !important;
    padding: 12px;
    //flex-grow: 1;
    font-size: 15px;
    img {
        outline: none;
    }
}

.adaptive-card-for-genee-box {
    outline: none;
    width: 360px !important;
    button {
        background: unset;
        font-size: medium;
        color: #1b75bb;
        padding: 4px;
    }
    button.style-destructive {
        color: #6e7376;
    }
    button:focus {
        outline: unset;
    }
    .ac-container .ac-columnSet .ac-container .ac-textBlock {
        padding: 8px !important;
        outline: none;
        font-family: 'Rubik-Regular' !important;
    }
    .ac-textRun{
       font-family: 'Rubik-Regular' !important;
    }
    .ac-textBlock{
        font-family: 'Rubik-Regular' !important;
    }
    img {
        outline: none;
    }
    input:focus {
        outline: none;
    }
    textarea:focus {
        outline: none;
    }
    div:focus {
        outline: none;
    }
    .ac-choiceSetInput-expanded {
        input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            border: 2px solid #999;
            // transition: 0.1s all linear;
            margin-right: 5px;
            position: relative;
        }
        input:checked {
            border: 6px solid #0066b8;
        }
    }
    .ac-choiceSetInput-multiSelect {
        input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #999;
            // transition: 0.1s all linear;
            margin-right: 5px;
            position: relative;
        }
        input:checked {
            left: 6px;
            top: 0px;
            width: 7px;
            height: 14px;
            border: solid #0066b8;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 0px;
        }
        input:checked~label {
            margin-left: 17px !important;
        }
    }
}

.signature-pad {
    &::part(content) {
        width: 85% !important;
        position: relative !important;
        top: unset !important;
        left: unset !important;
        transform-origin: unset !important;
        margin: auto;
    }
    @media only screen and (max-width: 500px) and (orientation: portrait) {
      &::part(content) {
        width: 350px !important;
        position: relative !important;
        top: unset !important;
        left: unset !important;
        transform-origin: unset !important;
      }
    }
}

.signature-pad-procedure-contract{
  &::part(content) {
    width: 350px !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform-origin: unset !important;
  }
}

.hop-qr-code-modal::part(content) {
  width: 350px !important;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  transform-origin: unset !important;
}

.mdm-material-modal::part(content) {
  width: 350px !important;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  transform-origin: unset !important;
}

#workCardInput, #practiceCertificateInput {
  ion-textarea {
    border: none !important;
    margin-top: 12px !important;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
}

.dx-overlay-shader {
    display: none;
}

ion-header ion-toolbar {
    &:first-of-type {
        padding-top: 0 !important;
    }
}

ion-toolbar.left-header-toolbar,
ion-toolbar.right-header-toolbar {
    min-height: var(--omni-header-height);
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: var(--omni-header-outer-padding);
    --padding-end: var(--omni-header-outer-padding);
    ion-icon {
        pointer-events: none;
    }
}

ion-toolbar.left-header-toolbar,
ion-toolbar.right-header-toolbar,
.safe-padding-top {
    &:first-of-type {
        padding-top: var(--ion-safe-area-top, 0) !important;
    }
    // iOS 11.0 - 11.2
    @supports (padding-top: constant(safe-area-inset-top)) {
        &:first-of-type {
            padding-top: constant(safe-area-inset-top) !important;
        }
    }
    // iOS 11.2+
    @supports (padding-top: env(safe-area-inset-top)) {
        &:first-of-type {
            padding-top: env(safe-area-inset-top) !important;
        }
    }
}

ion-modal.dynamic-height-modal>.modal-wrapper {
  margin-top: var(--ion-safe-area-top, 0) !important;
  // iOS 11.0 - 11.2
  @supports (padding-top: constant(safe-area-inset-top)) {
    margin-top: constant(safe-area-inset-top) !important;
  }
  // iOS 11.2+
  @supports (padding-top: env(safe-area-inset-top)) {
    margin-top: env(safe-area-inset-top) !important;
  }
}

// ion-toolbar.left-header-toolbar.toolbar-android, ion-toolbar.right-header-toolbar.toolbar-android, .safe-padding-top.toolbar-android {
//   &:first-of-type {
//     padding-top: var(--ion-safe-area-top, 0) !important;
//   }
// }

/*
 * This is a special rule for 'scheduler-popover' component to override the top safe padding.
 * This component can be both fullscreen or popover, we need to selectively change the padding
 * when it's not covering entire screen..
 */

.pop-over-mode ion-toolbar.left-header-toolbar {
    padding-top: 0 !important;
}

.right-header-background,
presentation-meeting {
  --background: var(--omni-right-header-primary-bg), var(--omni-left-header-fallback-bg);
}

ion-toolbar.right-header-background-HCP {
    --background: var(--omni-item-selected-background-color) !important;
}

ion-toolbar.left-header-toolbar {
    --background: var(--omni-left-header-primary-bg), var(--omni-left-header-fallback-bg);
    // Some places we have titles instead of search bar
    ion-title {
        color: white;
        width: 100%;
    }
    ion-menu-toggle {
        display: flex;
        .bar-button-menutoggle-ios {
            width: var(--omni-left-header-btn-size) !important;
            height: var(--omni-left-header-btn-size) !important;
            padding-right: var(--omni-header-inner-padding);
        }
        .notify-bubble {
            position: absolute;
            top: 23px;
            right: 9px;
            padding: 4px;
            background-color: var(--omni-custom-blue);
            color: var(--omni-custom-white);
            border-radius: 50%;
            -webkit-box-shadow: 1px 1px 1px grey;
            box-shadow: 1px 1px 1px grey;
        }
    }
    ion-buttons.start-slot,
    ion-buttons.end-slot {
        height: var(--omni-header-height);
        ion-button {
            --color: white;
            padding: 0;
            margin: 0;
            height: var(--omni-left-header-btn-size) !important;
            ion-icon {
                width: var(--omni-left-header-btn-size) !important;
                // height: var(--omni-left-header-btn-size) !important;
            }
        }
    }
    ion-buttons.start-slot ion-button {
        --padding-start: 0;
        --padding-end: var(--omni-header-inner-padding);
    }
    ion-buttons.end-slot ion-button {
        --padding-start: var(--omni-header-inner-padding);
        --padding-end: 0;
    }
    .ion-header-left-title {
        color: white;
        font-weight: 500;
        font-family: 'Rubik-Regular';
        font-size: var(--omni-left-header-searchbar-placeholder-small-size);
        text-overflow: ellipsis;
    }
    .searchbar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        ion-searchbar {
            padding: 0;
            height: var(--omni-left-header-searchbar-height);
            --background: var(--omni-left-header-searchbar-bg);
            --color: white;
            --icon-color: white;
            --clear-button-color: white;
            --cancel-button-color: white;
            --placeholder-color: white;
            --placeholder-font-weight: 500;
            --placeholder-opacity: 1;
            .searchbar-input-container {
                input {
                  font-family: 'Rubik-Regular';
                  font-size: var(--omni-left-header-searchbar-placeholder-small-size);
                  text-overflow: ellipsis;

                  &::placeholder,
                  &::-webkit-input-placeholder,
                  &::-moz-placeholder,
                  &:-moz-placeholder,
                  &:-ms-input-placeholder {
                    font-family: 'Rubik-Regular';
                    font-size: var(--omni-left-header-searchbar-placeholder-small-size);
                    text-overflow: ellipsis;
                  }
                }
              }
        }
        ion-searchbar.searchbar-has-focus {
            --placeholder-color: white;
            --placeholder-opacity: 0.5;
        }
        .searchbar-overlay {
          height: var(--omni-left-header-searchbar-height);
          // top: 0;
          right: 0;
          display: flex;
          position: absolute;
          align-items: center;
            ion-button.searchbar-overlay-button {
                height: 36px;
                width: 32px;
                margin: 0;
                padding-right: 8px;
                --color: white;
                --opacity: 1;
                --padding-start: 0;
                --padding-end: 0;
                --overflow: visible;
            }
            .searchbar-filter-badge {
                position: absolute;
                top: 3px;
                right: -6px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                border-radius: 50%;
                font-size: 12px;
                text-align: center;
                background: #4caf50;
            }
        }
    }
}

ion-toolbar.right-header-toolbar {
    --background: var(--omni-right-header-primary-bg), var(--omni-right-header-fallback-bg);
    .right-header-toolbar-inner-wrapper {
        display: flex;
        .right-header-title {
            height: var(--omni-header-height);
            line-height: var(--omni-header-height);
            color: white;
            font-family: 'Rubik-Regular';
            font-size: var(--omni-left-header-searchbar-title-size);
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: auto;
            min-width: calc(45% - 32px);
        }
        ion-buttons {
            height: var(--omni-header-height);
            ion-button {
                --color: white;
            }
            ion-button[disabled] {
                --color: var(--omni-text-color-secondary) !important;
            }
        }
        ion-buttons.start-slot {
            ion-button {
                margin: -5px;
                --color: white;
                --padding-start: 0;
                --padding-end: 8px;
            }
        }
        ion-buttons.end-slot {
            justify-content: flex-end;
            margin-left: auto;
            min-width: 185px;

            ion-button {
                height: 32px;
                padding: 0;
                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
            ion-button.has-label {
                height: 58px;
                padding-top: 5px;
                min-width: 40px;
                --padding-start: 0;
                --padding-end: 0;
                margin-left: 2px;
                margin-right: 2px;

                &:first-of-type {
                  margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
            .right-header-toolbar-btn-inner-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
            ion-icon {
                width: 24px;
                height: 24px;
            }
            ion-icon.has-label {
                width: 22px;
                height: 22px;
            }
            ion-label {
                font-family: 'Rubik-Regular';
                font-size: var(--omni-right-header-button-label-size);
                margin-top: 3px;
                width: 100%;
                line-height: 1;
                white-space: normal;
            }
        }
    }
}

ion-toolbar.impersontated-view {
    --background: #6889A3;
}

.container-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Inserts safe area inset bottom for iOS devices with no home buttons
// [base-page] should be added to the selector of each master page
[base-page],
ion-modal>.ion-page,
ion-modal>.ion-page ion-nav .ion-page,
ion-modal.dynamic-height-modal>.modal-wrapper,
tool-drawer-menu ion-grid {
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  // iOS 11.2+
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
      padding-bottom: env(safe-area-inset-bottom);
  }
  &>ion-grid>ion-row>ion-col {
    display: flex;
    flex-flow: column;
  }
}

ion-modal.calendar-invite-modal::part(content) {
  transform: none;
}

ion-modal.customer-availability-modal::part(content) {
  width: 60%;
  position: absolute;
  left: 20%;
  top: 25%;
  height: 50%;
  opacity: 1;
  border-radius: 10px;
}
@media only screen and (max-width: 500px) and (orientation:portrait) {
  ion-modal.customer-availability-modal::part(content) {
      width: 90%;
      position: absolute;
      left: 5%;
      top: 25%;
      height: 50%;
      opacity: 1;
      border-radius: 10px;
  }
}

@mixin inde_h1 {
    // write the css here
    font-family: 'SF-Pro-Rounded-Bold';
    font-size: var(--omni-h1-font-size) !important;
}

@mixin inde_h2 {
    font-family: 'SF-Pro-Rounded-Bold';
    font-size: var(--omni-h2-font-size) !important;
}

@mixin inde_h3 {
    font-family: 'SF-Pro-Rounded-Semibold';
    font-size: var(--omni-h3-font-size) !important;
}

@mixin inde_h4 {
    font-family: 'SF-Pro-Rounded-Semibold' !important;
    font-size: var(--omni-h4-font-size) !important;
    text-transform: capitalize;
}

@mixin inde_h5 {
    font-family: var(--omni-font-family-base);
    font-size: var(--omni-h4-font-size);
    font-weight: var(--omni-font-weight-light);
}

@mixin inde_body_text {
    font-family: var(--omni-font-family-base);
    font-size: var(--omni-font-size-base);
    font-weight: var(--omni-font-weight-regular);
}

@mixin inde_body_text2 {
    font-family: var(--omni-font-family-base);
    font-size: var(--omni-font-size-base);
    font-weight: var(--omni-font-weight-regular);
}

@mixin inde_sec_text {
    font-family: var(--omni-font-family-base);
    font-size: var(--omni-sec-text-font-size);
    font-weight: var(--font-weight-regular);
    color: var(--omni-text-color-tetiary);
}

@mixin inde_body_large_text {
    color: var(--omni-text-color-large) !important;
    font-size: var(--omni-large-text-font-size) !important;
    font-family: 'Rubik-Light';
}

@mixin inde_body_primary_text {
    color: var(--omni-text-color-primary) !important;
    font-size: var(--omni-primary-text-font-size) !important;
    font-family: 'Rubik-Regular';
}

@mixin inde_body_secondary_text {
    color: var(--omni-text-color-secondary) !important;
    font-size: var(--omni-secondary-text-font-size) !important;
    font-family: 'Rubik-Regular';
}

@mixin inde_body_tertiary_text {
    color: var(--omni-text-color-tetiary) !important;
    font-size: var(--omni-tertiary-text-font-size) !important;
    font-family: 'Rubik-Regular';
}

@mixin inde_body_placeholder_text {
    color: var(--omni-placeholder-color);
    font-size: var(--omni-form-placeholder-font-size);
    font-family: 'Rubik-Regular';
}

@mixin inde_section_header_control_text {
    font-family: 'Rubik-Regular';
}

.primary-text,
.primary-text h3 {
    @include inde_body_primary_text;
}

.secondary-text {
    @include inde_body_secondary_text;
}

.tertiary-text {
    @include inde_body_tertiary_text;
}

ind-page-title .title {
    @include inde_h3;
}

ind-section-header {
    ion-buttons ion-button {
        @include inde_section_header_control_text;
    }
    ion-label {
        @include inde_h4;
    }
}

.page-title ion-title {
    @include inde_h2;
    //  &.leftScreenHeaderTitle {
    //    @include inde_h2;
    //  }
}

scheduler-item {
    expandable {
        .expand-wrapper {
            height: unset !important;
            margin-left: 14px;
            padding-top: unset !important;
            span {
                margin-right: 10px;
            }
        }
    }
}


@media (min-width: 401px) and (max-width: 500px) {
  activity-day-view {
    .week-view .mbsc-cal-day-picker {
      margin-left: 51px;
    }
  }
}

@media (min-width: 540px) {
  ion-modal.popup-modal::part(content) {
    max-width: 90%;
  }
}

@media (min-width: 780px) {
  activity-day-view {
    .mbsc-cal-month,
    .three-days-view-month-label {
      margin-left: 20px !important;
    }
  }
}

@media (min-width: 1003px) {
  activity-day-view {
    .week-view .mbsc-cal-day-picker {
      margin-left: 51px;
    }
  }
}

@media (min-width: 1024px) {
  ion-modal.popup-modal::part(content) {
    max-width: 1024px;
  }
}

@media (min-width: 1922px) {
  activity-day-view {
    .week-view .mbsc-cal-day-picker .mbsc-cal-scroll-c {
      border-left: unset;
    }
  }
}

@media only screen and (max-width: 500px) {
    .leftScreenHeaderTitle,
    .main-tool-header-title {
        font-size: 16px !important;
    }

    ion-toolbar.right-header-toolbar
    .right-header-toolbar-inner-wrapper
    .right-header-title {
      min-width: calc(45% - 55px);
    }

    ion-toolbar.right-header-toolbar
    .right-header-toolbar-inner-wrapper
    ion-buttons.end-slot {
      min-width: 48%;
    }
}

@media only screen and (max-width: 450px) {
    .leftScreenHeaderTitle,
    .main-tool-header-title {
        font-size: 16px !important;
    }
}

@media (max-width: 400px) {
  ion-toolbar.right-header-toolbar
  .right-header-toolbar-inner-wrapper
  ion-buttons.end-slot {
    min-width: 50%;
  }
}

@media only screen and (max-width: 768px) and (orientation:landscape) {
    .leftScreenHeaderTitle,
    .main-tool-header-title {
        font-size: 32px !important;
    }
}

ion-select::part(icon) {
    opacity: 1 !important;
}

.dropdown-list-view2::part(content) {
  transform: translateXY(-100%, -100%);
}

.consents-filter-popover ion-content {
  height: auto;
}

@media only screen and (min-width: 1200px) {
    .segment-dropdown-list-view {
        &::part(content)::-webkit-scrollbar {
            display: block !important;
        }
        &::part(content)::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
        ion-content::-webkit-scrollbar {
            display: block !important;
        }
        ion-content::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
    }
}

.info-box {
    margin-bottom: 12px;
    margin-right: 12px;
    #address {
        white-space: normal !important;
    }
}

customer-insight {
    main-card {
        ion-label {
            margin-left: 0px !important;
            margin-bottom: 0px!important;
        }
    }
    ion-item-group {
        ion-list {
            &>ion-item::part(native) {
                padding-left: 16px !important;
            }
        }
    }
}

.empty-item {
    min-height: 88px;
    background-color: var(--omni-bg-color4);
    --border-width: 0px;
    --inner-border-width: 0px;
    .item-inner {
        border-bottom: none !important;
    }
}

.collapsed-item {
  max-height: 5px;
  background-color: var(--omni-bg-color4);
  --border-width: 0px;
  --inner-border-width: 0px;
  .item-inner {
      border-bottom: none !important;
  }
}

.min-section-ht {
    min-height: 88px;
}

.coaching-activity-filter {
    ion-item::part(native) {
        padding-left: 0px !important;
    }
    ion-item {
        ion-label {
            padding-left: 20px !important;
        }
    }
}

ion-item::part(detail-icon) {
    opacity: 1 !important;
    color: var(--omni-primary-color1);
}

customer-insight {
    main-card {
        .separationLine {
            display: none !important;
        }
    }
}

.journey-inisghts-filter {
    ion-icon.checkmarkIcon {
        width: 12px !important;
        --ionicon-stroke-width: 70px !important;
        margin-left: 10px !important;
    }
}

.segment-dropdown-list-view {
    ion-item::part(native) {
        padding-left: 0px !important;
    }
    ion-checkbox {
        margin-left: 20px !important;
    }
}

// only for andriod tab
@media only screen and (min-height: 320px) and (max-height: 660px) {
    .hcp-margin {
        margin-top: 3px;
        margin-right: 35px;
    }
}

.suggestionNameBubble {
    cursor: pointer;
}

p-dropdown {
  right: 16px !important;
  position: absolute !important;
  top: 13px !important;
}

.p-paginator {
  position: sticky;
  bottom: 0px !important;
  left: 0px !important;
  padding: 5px 0px !important;
  height: 50px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--omni-highlighted-section-header-background) !important;
  border-color: var(--omni-highlighted-section-header-background) !important;
  color: var(--omni-text-color-primary) !important;
}

.p-paginator-element {
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: var(--omni-text-color-secondary);
}
.p-link:focus {
  box-shadow: none !important;
}

.p-link {
  font-size: var(--omni-secondary-text-font-size) !important;
  font-family: var(--omni-font-family-base) !important;
  border-radius: 3px;
}

.p-component {
  font-family: var(--omni-font-family-base) !important;
  font-size: var(--omni-secondary-text-font-size) !important;
  font-weight: 400;
}

.mandatory-icon {
  background-image: url(../assets/imgs/asterisk.svg);
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 12px;
}

.accountwidgeticon {
  background-image: url(../assets/imgs/accountWidget.svg);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0px;
  height: 32px;
  width: 32px;
}

.target-contact-icon {
  background-image: url(../assets/imgs/target-icon-blue.svg);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0px;
  height: 12px;
  width: 12px;
}

.target-contact-icon-selected {
  background-image: url(../assets/imgs/target-icon-white.svg);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0px;
  height: 12px;
  width: 12px;
}

.hide-notes {
  .textarea-wrapper, textarea {
    display: none;
  }
  ion-textarea {
    height: 50px;
  }
}

@media (max-device-width: 1023px) and (orientation: landscape) {
  .timepicker {
    width: 300px !important;
    display: block !important;
  }
  .timepicker__header {
    display: block !important;
  }
  .timepicker-dial__container {
    flex-direction: row !important;
  }
}

market-scan-details,
time-off-details {
  #save {
    ion-icon {
      padding-bottom: 1px !important;
    }
  }
}

.in-progress {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  background: var(--omni-custom-white);

  ion-spinner {
    height: var(--omni-secondary-text-font-size);
    margin-top: 1px;
  }
}

ion-progress-bar {
  height: 6px;
}

.left-progress-bar {
  --progress-background: var(--omni-left-progress-bar-primary-bg);
  --background: var(--omni-right-progress-bar-primary-bg);
}

.right-progress-bar {
  --progress-background: var(--omni-right-progress-bar-primary-bg);
  --background: var(--omni-left-progress-bar-primary-bg);
}

appsettings-list,
app-language-list,
io-multiselect,
coaching-activities,
main-tool-template,
select-list,
scientific-activity-page,
child-users-page,
case-popover,
events-list,
date-formats-page {
  .in-progress {
    padding-bottom: 5px;
  }
}

.show-progress-bar-pad {
  --padding-top: 5px !important;
}


path.link {
  fill: none;
  stroke: #CCC;
  stroke-width: 2px;
}

ion-item.no-line {
    --border-width: 0px;
    --inner-border-width: 0px;
   }

.small-text {
    font-size: var(--omni-section-header-controls-font-size-xxxx-small) !important;
    color: var(--omni-text-color-secondary);
}

customer-call-plan-overview .title-text.has-right-label {
  max-width: calc(100% - 130px) !important;
}

.custom-tooltip > div {
  font-size: 14px;
}

.tooltip-shadow.custom-tooltip {
  border-radius: unset !important;
  box-shadow: unset !important;
  background: #ffffff !important;
  border: 1px solid black !important;
}

.custom-tooltip > .tooltip-arrow {
  display: none !important;
}

ion-modal.filter-menu-right-modal {
  padding-top: var(--omni-header-height);
  padding-left: 60%;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  ion-modal.filter-menu-right-modal {
    padding-left: 30%;
  }
}

#TinetToolbar {
  font-family: var(--omni-font-family-base) !important;
  #tinet-toolbar .tinet-header {
    margin-left : 20px !important;
    justify-content: center !important;
    width: auto !important;
    .tinet-split-line, .tinet-status {
      display: none !important;
    }
    .tinet-phone-num {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

ion-modal.filter-menu-right-modal {
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}

.teamview-template-dropdown-list-view::part(content) {
  left: unset !important;
}

search-filter {
  .searchbar-overlay {
    position: absolute;
    height: var(--omni-left-header-searchbar-height);
    top: 4px;
    right: 0;
  }

  ion-searchbar {
   padding: 0px !important;
   --padding: 0px;
   background: none;
   --background: none;
   top: 5px !important;

   .searchbar-input-container {
     padding: 0px;
     --padding: 0px;
     background: none;
     --background: none;
     border-bottom: 1px solid #979797;
   }
 }

  .searchbar-filter-badge {
    position: absolute;
    top: 3px;
    right: -6px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    background: #D9E6F5;
  }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
}

.time-line-list {
  position: relative;
  margin-left: 16px;
}

.time-line-list::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 5.1px;
  right: 0px;
  bottom: 0px;
  width: 0;
  border-left: 1px solid var(--omni-custom-blue);
}

.borderless-time-line-list{
  position: relative;
  // margin-left: 16px;
}

.list-preview-popover::part(content) {
  min-width: 320px;
}

.hide-input {
  height: 0;
  width: 0;
  display: none;
}

// Activity calendar component mobiscroll styles
activity-calendar {
  font-family: "Rubik-Regular";
  color: var(--ion-text-color);

  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header,
  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days,
  .mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day,
  .mbsc-ios.mbsc-schedule-wrapper {
    background-color: white;
  }

  .ind-mbsc-scheduler-selected-cell {
    box-shadow: inset 0 0 0 1px #337ab7;
    background-color: #dbe9f5;
  }

  ion-content.week-view, .three-days-view {
    mbsc-scheduler {
      mbsc-week-day {
        .mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
          background: white;
          color: #007AFF;
        }
      }
    }
  }

  ion-content.day-view .mbsc-schedule-date-header {
    display: none !important;
  }

  .ind-calendar-header {
    mbsc-button.mbsc-ios.mbsc-button-flat {
      margin: 0;
      .mbsc-font-icon::before {
        font-size: 1.2em;
        font-weight: 900;
      }
    }
  }

  .ind-calendar-custom-header-nav-wrapper {
    width: calc((100% - 80px) * 0.6);
    display: flex;
    justify-content: start;

    .ind-calendar-custom-header-nav {
      width: 100%;
      mbsc-button.mbsc-calendar-button {
        color: var(--omni-text-color-primary);
        width: auto;
        max-width: 100%;
        padding-right: 0;

        .mbsc-calendar-title {
          padding-left: 0;
        }
        .mbsc-calendar-year {
          padding-right: 0;
        }
      }
    }
  }

  .ind-calendar-custom-header-view-controller {
    width: calc((100% - 80px) * 0.4);
    display: flex;
    justify-content: end;

    ion-button {
      margin-inline: 0;
      --padding-start: 0;
      --padding-end: 0;
    }
  }

  .ind-calendar-prev-next {
    mbsc-button.mbsc-calendar-button.mbsc-button.mbsc-reset {
      width: 40px;
    }
  }

  @media only screen and (min-width: 500px) and (max-width: 650px) {
    .ind-calendar-custom-header-nav-wrapper {
      width: calc((100% - 48px) * 0.55);
    }
    .ind-calendar-prev-next {
      mbsc-button.mbsc-calendar-button.mbsc-button.mbsc-reset {
        width: 24px;
      }
    }
    .ind-calendar-custom-header-view-controller {
      width: calc((100% - 48px) * 0.45);
    }
  }

  .ind-mbsc-schedule-activity-background {
    background: rgb(194, 230, 255);
    border-radius: 0.6em;
  }

  .mbsc-ios.mbsc-calendar-height-md .mbsc-calendar-day:after {
    border-left-style: none;
  }

  .mbsc-ios.mbsc-schedule-all-day-text {
    text-transform: none;
    font-size: var(--omni-secondary-text-font-size)
  }

  .mbsc-schedule-event-small-height.mbsc-schedule-event-inner {
    padding-top: 1px;
  }

  .ind-activity-title {
    color: var(--ion-text-color) !important;
  }

  .mbsc-schedule-event-all-day {
    width: 100% !important;
  }

  mbsc-time-indicator .mbsc-schedule-time-indicator-time-x {
    display: none;
  }

  .mbsc-schedule-time-wrapper {
    width: 4.3em;
  }

  .mbsc-ios.mbsc-schedule-time {
    font-size: var(--omni-secondary-text-font-size);
  }

  mbsc-event-list-item .mbsc-event-date {
    display: none;
  }

}
io-content-widget {
  ion-segment {
    min-height: 32px !important;
    gap: 16px;
  }

  ion-segment-button {
    min-height: 32px !important;
    --padding-start: 0px;
    --padding-end: 0px;
  }
}
short-call-home {
  #content-container {
    ind-tabs {
      display: flex;
      width: calc(100% - 32px);
    }
    ion-segment {
      min-height: 32px !important;
      gap: 16px;
    }

    ion-segment-button {
      min-height: 32px !important;
      --padding-start: 0px;
      --padding-end: 0px;
      max-width: 100px;
      overflow: hidden;
    }
  }
}
presentation-meeting.short-call {
  .arrow-float-left, .arrow-float-right, .sentiments, .overlay-play-button {
    display: none !important;

  }
  footer-toolbar {
    ion-fab {
      .ogeenee-btn-fullscreen {
        display: none;
      }
      ion-row {
        justify-content: space-around;
      }
    }
  }
}


.short-call-details,
.short-call-home,
.io-contact-list,
.dynamic-height-modal {
  &::part(content) {
    min-height: 185px;
    height: auto;
    position: absolute;
    bottom: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .key-message-sentiment-cards ion-slides {
    .swiper-wrapper {
      margin-bottom: 22px;
    }
    .swiper-pagination {
      bottom: 0;
    }
  }
}

span.highlight {
  color: var(--omni-primary-color1) !important;
}

.rcw-message {
  overflow-y: scroll !important;
  scroll-behavior: smooth;
}
.rcw-conversation-container > .rcw-header{
  padding: 0;
  background: var(--omni-right-header-primary-bg), var(--omni-right-header-fallback-bg);
}
.rcw-message .rcw-avatar{
  object-fit: cover;
}
.rcw-client .rcw-message-text, .rcw-launcher{
  background-color: var(--omni-highlighted-section-header-background)
}
.rcw-close-launcher{
  object-fit: none;
}
.rcw-picker-btn{
  margin-right: 10px;
}
.rcw-send{
  padding: 7px;
}

.searchSuggestionWrapper {
  ion-item:hover {
    --ion-background-color: var(--omni-highlighted-section-header-background) !important;
    background-color: var(--omni-highlighted-section-header-background) !important;
  }
}

.no-margin {
  margin: var(--omni-no-margin);
}
// .rcw-new-message{
//   max-width: 250px;
// span.highlight {
//   color: var(--omni-primary-color1) !important;
// }



// @media screen and (max-width: 800px){
//   .rcw-widget-container {
//     height: 80vh;
//     margin: 0;
//     max-width: none;
//     width: 80%;
//     bottom: 50px;
//     right: 50px;
//   }
// }

.sync-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--omni-custom-red);
  padding: 2px;
  margin-bottom: 4px;
  min-height: 33px;

  .banner-text {
    font-size:14px;
    color: var(--ion-color-primary-contrast);
    margin-left: 1rem;
  }

  .banner-text-offline {
    padding: 4px;
  }

  ion-button {
    --color: var(--ion-color-primary-contrast);
    --border-color: var(--ion-color-primary-contrast);
    --background-activated: none;
    font-size: 14px;
    margin-right: 4px;
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  height: 100%;
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.searchbar-overlay-button-filter-icon{
  height: 32px;
  width: 24px;
}

.omni-alert-input {
  font-size: 13px !important;
  height: 29px;
  border-radius: 4px !important;
}

.notification-bell-icon{
  height: 23px;
  width: 23px;
}