:root {
    --background-color: #ffffff;
    /** primary **/
    --ion-color-primary: #007AFF;
    --ion-color-primary-rgb: 0, 122, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #065A9B;
    --ion-color-primary-tint: #4c8dff;
    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-text-color: #0D0D0D;
}

:root {
    --omni-text-color-primary: #0D0D0D;
    --omni-text-color-secondary: #737373;
    --omni-text-color-tetiary: #737373;
    --omni-text-color-disabled: #A6A6A6;
    --omni-text-color-large: #0D0D0D;
    --omni-text-color-arrow: #c8c7cc;
    --omni-text-color: #0D0D0D;
    --omni-text-color1: #0D0D0D;
    --omni-text-color2: #737373;
    --omni-secondary-text-color: #737373;
    --omni-text-color4: #A6A6A6;
    --omni-bg-color1: #fafafa;
    --omni-bg-color2: #efeff4;
    --omni-bg-color3: #f8f8f8;
    --omni-bg-color4: #fff;
    --omni-accordion-background-color: #f8f8f8;
    --omni-bg-color4-rgb: 255, 255, 255;
    --omni-primary-color1: #007AFF;
    --omni-primary-color2: #66D68D;
    --omni-primary-color3: #d0021b;
    --omni-primary-color4: #fdd835;
    --omni-user-input-color: #A6A6A6;
    --omni-label-color: #737373;
    --omni-placeholder-color: #A6A6A6;
    --omni-custom-red: #D0021B;
    --omni-custom-blue: #007AFF;
    --omni-custom-green: #66D68D;
    --omni-custom-green2: #8BC53F;
    --omni-custom-white: #FFFFFF;
    --omni-custom-grey: #EFEFF4;
    --omni-custom-light-grey: #F5F5F5;
    --omni-custom-dark-grey: #0D0D0D;
    --omni-form-field-border-line: #DFDFDF;
    --omni-form-field-border-line-in-focus: #007AFF;
    --omni-form-field-border-line-invalid: #FF006E;
    --omni-section-header-label-color: #0D0D0D;
    --omni-section-header-button-color: #007AFF;
    --omni-item-selected-background-color: #F2F2F2;
    --omni-text-error-message-color: #FF006E;
    --omni-font-size-base: 1.6rem;
    --omni-h1-font-size: 48px;
    --omni-h2-font-size: 32px;
    --omni-h3-font-size: 24px;
    --omni-h4-font-size: 20px;
    --omni-sec-text-font-size: 1.3rem;
    --omni-item-ios-body-text-font-size: 1.6rem;
    --omni-page-title-left-side-font-size: 22px;
    --omni-page-title-right-side-font-size: 16px;
    --omni-primary-text-font-size: 16px;
    --omni-form-label-font-size: 12px;
    --omni-form-placeholder-font-size: 16px;
    --omni-form-input-font-size: 16px;
    --omni-section-header-font-size: 13px;
    --omni-section-fab-text-font-size: 10px;
    --omni-section-header-label-font-size: 16px;
    --omni-section-header-controls-font-size: 16px;
    --omni-section-header-controls-font-size-small: 14px;
    --omni-section-header-controls-font-size-x-small: 13px;
    --omni-section-header-controls-font-size-xx-small: 12px;
    --omni-section-header-controls-font-size-xxx-small: 11px;
    --omni-section-header-controls-font-size-xxxx-small: 10px;
    --omni-secondary-text-font-size: 14px;
    --omni-controls-font-size: 14px;
    --omni-tabs-font-size: 14px;
    --omni-tertiary-text-font-size: 12px;
    --omni-large-text-font-size: 40px;
    --omni-expand-text-font-size: 14px;
    --omni-genee-comment-font-size: 15px;
    --omni-back-to-home-btn-size: 32px;
    --omni-selectors-icon-size: 24px;
    --omni-font-weight-light: 300;
    --omni-font-weight-regular: 400;
    --omni-font-weight-medium: 500;
    --omni-title-header-height: 50px;
    --omni-app-footer-height: 50px;
    --omni-app-footer-container-height: 60px;
    --omni-section-header-height: 40px;
    --omni-level-four-padding: 20px;
    --omni-level-three-padding: 15px;
    --omni-level-two-padding: 10px;
    --omni-level-one-padding: 5px;
    --omni-no-padding: 0px;
    --omni-bottom-padding: 24px;
    --omni-level-three-margin: 15px;
    --omni-no-margin: 0px;
    --omni-section-controls-margin-top: 8px;
    --omni-section-header-arrow-size: 24px;
    --omni-no-padding: 0px;
    --omni-available-width: stretch;
    --omni-highlighted-section-header-background: #D9EBFF;
    --omni-status-bar-force-padding: 20px;
    --omni-header-height: 72px;
    --omni-header-height-with-location: 105px;
    --omni-header-outer-padding: 16px;
    --omni-header-inner-padding: 8px;
    --omni-genee-suggestions-border: #FA3365;
    --omni-left-header-primary-bg: linear-gradient(90deg, rgba(252, 50, 100, 1) 0%, rgba(101, 87, 165, 1) 33%, rgba(0, 111, 209, 1) 100%);
    --omni-left-header-fallback-bg: rgb(0, 111, 209);
    --omni-left-header-searchbar-bg: rgba(0, 0, 0, 0.3);
    --omni-left-header-searchbar-title-size: 24px;
    --omni-left-header-searchbar-placeholder-small-size: 21px;
    --omni-left-header-btn-size: 32px;
    --omni-left-header-searchbar-height: 36px;
    --omni-right-header-primary-bg: linear-gradient(90deg, rgba(0, 111, 209, 1) 0%, rgba(32, 127, 187, 1) 43%, rgba(170, 195, 92, 1) 85%, rgba(20, 218, 149, 1) 100%);
    --omni-right-header-fallback-bg: rgb(0, 111, 209);
    --omni-right-header-button-label-size: 10px;
    --omni-menu-toolbar-footer-height: 72px;
    --omni-menu-toolbar-browser-electron-top-padding: 16px;
    --omni-left-progress-bar-primary-bg: linear-gradient(90deg, #FFBFBF, #40FFBA);
    --omni-right-progress-bar-primary-bg: linear-gradient(90deg, #40FFBA, #FFBFBF);
    --omni-custom-orange: #FF650D;
    --omni-toolbar-segment-height: 44px;
}
